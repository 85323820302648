const EVENT_NAMES = ['Kostka 3x3x3', 'Kostka 2x2x2', 'Kostka 4x4x4', 'Kostka 5x5x5', 'Kostka 6x6x6', 'Kostka 7x7x7',
    '3x3x3 Bez patrzenia', '3x3x3 Najmniejsza liczba ruchów', '3x3x3 Jedną ręką', 'Clock', 'Megaminx', 'Pyraminx',
    'Skewb', 'Square-1', '4x4x4 Bez patrzenia', '5x5x5 Bez patrzenia', '3x3x3 Wiele kostek bez patrzenia'];

const EVENT_ICONS = ['333', '222', '444', '555', '666', '777', '333bf', '333fm', '333oh', 'clock', 'minx', 'pyram',
    'skewb', 'sq1', '444bf', '555bf', '333mbf'];

const ROUND_NAMES = ['Pierwsza', 'Druga', 'Półfinał', 'Finał'];

let object = {EVENT_NAMES, EVENT_ICONS, ROUND_NAMES};

object.getEventName = function (eventId) {
    return EVENT_NAMES[eventId];
}

object.getEventIcon = function (eventId) {
    return '$event-' + EVENT_ICONS[eventId];
}

object.getRoundName = function (roundId) {
    return ROUND_NAMES[roundId - 1];
}

object.getRoundLiveId = function (roundId) {
    return ['r1', 'r2', 'r3', 'f'].at(roundId - 1);
}

object.getEventLiveId = function (eventId) {
    return EVENT_ICONS[eventId];
}

export default object;
