let object = {};

object.parseResult = function (result, eventId) {
    if (result === -2) return 'DNS';
    if (result === -1) return 'DNF';
    if (result === 0) return '';

    if (eventId === 7)
        return result <= 200 ? result : Math.floor(result / 100) + '.' + (result % 100).toString().padStart(2, '0');

    if (eventId === 16) {
        let d = Math.floor(result / 10000000) % 100;
        let t = Math.floor(result / 100) % 100000;
        let m = result % 100;
        return (99 - d + m) + '/' + (99 - d + m + m) + ' ' + Math.floor(t / 60) + ':' + (t % 60).toString().padStart(2, '0');
    }

    let ss = result % 100;
    let s = Math.floor(result / 100) % 60;
    let m = Math.floor(result / 6000)

    if (m === 0 && s === 0)
        return '0.' + ss.toString().padStart(2, '0');

    if (m === 0)
        return s + '.' + ss.toString().padStart(2, '0');

    return m + ':' + s.toString().padStart(2, '0') + '.' + ss.toString().padStart(2, '0')
}

export default object;
